import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

const routes = [
    {
        path: '/',
        name: 'homepage',
        component: () => import('./pages/Homepage.vue')
    },
    {
        path: "/hrws",
        beforeEnter() {
            /*window.location.href = "http://spbbackup.ddns.net:88/webslip/";  BackUp */
            /*window.location.href = "http://976c0913ae74.sn.mynetname.net:88/webslip/";*/
            window.location.href = "http://hcq08fg3rac.sn.mynetname.net:88/webslip/WebLogin.aspx";
        }
    },
    {
        path: "/hrwt",
        beforeEnter() {
            /*window.location.href = "http://spbbackup.ddns.net:88/webtime/";  BackUp */
            /*window.location.href = "http://976c0913ae74.sn.mynetname.net:88/webtime/";*/
            window.location.href = "http://hcq08fg3rac.sn.mynetname.net:88/webtime/default1.aspx";
        }
    },
    {
        path: "/hrpl",
        beforeEnter() {
            /*window.location.href = "https://forms.office.com/r/BVuBHMBe3C";  recruit@sangpaibul.com */
            window.location.href = "https://forms.office.com/r/s3qMhyYaWq" /* hr-center@sangpaibul.com */
        }
    },
    {
        path: "/hrcv",
        beforeEnter() {
            window.location.href = "https://forms.office.com/r/gJQEbHHKPh";
        }
    },
    {
        path: "/itRequest",
        beforeEnter() {
            window.location.href = "https://service.co.th/sangpaibul"; /*https://forms.office.com/r/5GzMZ0Lkc1*/
        }
    },
    {
        path:"/itAgent",
        beforeEnter(){
            window.location.href = "https://forms.office.com/r/61BnpZJ3KV";
        }
    },
    {
        path:"/itHelpdesk",
        beforeEnter(){
            window.location.href = "http://976c0913ae74.sn.mynetname.net:90";
        }
    },
    {   
        path: "/ticketStatus",
        beforeEnter() {
            window.location.href = "https://sangpaibul-my.sharepoint.com/:x:/p/suttipong_bua/EXP0wxxYYYtGgcVy53h5npYBkHL7D8keK2t3B8tZzHAXrw?e=2b9fk4";
        }
    },
    {
        path: "/wmsb",
        beforeEnter() {
            window.location.href = "https://outlook.office.com";
        }
    },
    {
        path: "/wmpy",
        beforeEnter() {
            window.location.href = "https://phayak.com/roundcube/";
        }
    },
    {
        path: "/wmpt",
        beforeEnter() {
            window.location.href = "https://payuktrans.com/roundcube/";
        }
    },
    {
        path: "/amrq",
        beforeEnter() {
            window.location.href = "https://service.co.th/sangpaibul";
        }
    },
    {
        path: "/ampl",
        beforeEnter() {
            window.location.href = "https://forms.office.com/Pages/ResponsePage.aspx?id=UFxM8pfTq0qqXMYqvqxbD3FVf-na3udHuWg9hVvtZDhUQUY0T0NRN0lKOTZXNVNSTjZDTk5HUUoxTy4u";
        }
    },
    {
        path:"/pytg",
        beforeEnter(){
            window.location.href = "https://forms.gle/ttUUKf4d6LqDEPh76";
        }
    },
    {
        path: '/main',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            {
                path: '/empty',
                name: 'empty',
                component: () => import('./components/EmptyPage.vue')
            },
            {
                path: '/documentation',
                name: 'documentation',
                component: () => import('./components/Documentation.vue')
            },
            {
                path: '/button',
                name: 'button',
                component: () => import('./components/ButtonDemo.vue')
            },
            {
                path: '/input',
                name: 'input',
                component: () => import('./components/InputDemo.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
